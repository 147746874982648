import React, { useEffect } from 'react';

import { useClientNameLazyQuery } from '@vette/data-access';

type Props = { clientId?: string | null };

export const AdminTabNameHelper: React.FC<Props> = ({ clientId }) => {
  const [fetch] = useClientNameLazyQuery({
    variables: { clientId: clientId ?? '' },
    onCompleted: data => {
      if (data?.client?.__typename === 'Client') {
        document.title = data.client.companyName ?? 'Admin';
      }
    },
  });

  useEffect(() => {
    if (clientId) {
      fetch();
    } else {
      document.title = 'Admin';
    }
  }, [clientId, fetch]);

  return null;
};
