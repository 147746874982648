import React from 'react';
import 'antd/dist/antd.less';
import {
  firebase,
  next,
  AppContext,
  APP_CONTEXT_OPTIONS,
  isAdminAsClient,
  AdminTabNameHelper,
} from '@vette/frontend-utils';
import {
  ErrorFallback,
  GlobalStyles,
  theme,
  WarningProvider,
} from '@vette/ui-components';
import { ThemeProvider } from 'styled-components';
import * as redirects from '../config/redirects';
import Head from 'next/head';

export default next.app.createCustomApp({
  ErrorFallback,
  wrapComponent: node => (
    <ThemeProvider theme={theme}>
      <next.RouterPath>
        {path => (
          <AppContext.Provider
            value={
              isAdminAsClient(path)
                ? APP_CONTEXT_OPTIONS.adminAsClient
                : APP_CONTEXT_OPTIONS.admin
            }
          >
            <WarningProvider>
              <AdminTabNameHelper />
              <GlobalStyles />
              <firebase.RedirectGuard
                loggedInRedirects={redirects.loggedInRedirects}
                loggedOutRedirects={redirects.loggedOutRedirects}
              >
                <Head>
                  <title>Vette | Admin Application</title>
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <meta pingdom="3406FAE3C50262BA92B8503993B37833A01523F46A483DC38C3237F56F97FD59" />
                  }
                  <link
                    href="https://fonts.googleapis.com/icon?family=Material+Icons"
                    rel="stylesheet"
                  />
                </Head>
                {node}
              </firebase.RedirectGuard>
            </WarningProvider>
          </AppContext.Provider>
        )}
      </next.RouterPath>
    </ThemeProvider>
  ),
});
