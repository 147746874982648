import React from 'react';
import { useRouter } from 'next/router';

type Props = {
  fallback?: React.ReactNode;
  children: (path: string) => React.ReactNode;
};

export const RouterPath: React.FC<Props> = ({ children, fallback }) => {
  const router = useRouter();

  if (!router.asPath) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{fallback || <div>Loading...</div>}</>;
  }

  return <>{children(router.asPath)}</>;
};
